/* eslint-disable prefer-destructuring */
import { ref } from '@vue/composition-api'

const COUNTRIES = {
  AD: 'Andorra',
  AE: 'United Arab Emirates',
  AF: 'Afghanistan',
  AG: 'Antigua and Barbuda',
  AI: 'Anguilla',
  AL: 'Albania',
  AM: 'Armenia',
  AO: 'Angola',
  AQ: 'Antarctica',
  AR: 'Argentina',
  AS: 'American Samoa',
  AT: 'Austria',
  AU: 'Australia',
  AW: 'Aruba',
  AX: 'Åland Islands',
  AZ: 'Azerbaijan',
  BA: 'Bosnia and Herzegovina',
  BB: 'Barbados',
  BD: 'Bangladesh',
  BE: 'Belgium',
  BF: 'Burkina Faso',
  BG: 'Bulgaria',
  BH: 'Bahrain',
  BI: 'Burundi',
  BJ: 'Benin',
  BL: 'Saint Barthélemy',
  BM: 'Bermuda',
  BN: 'Brunei',
  BO: 'Bolivia',
  BQ: 'Caribbean Netherlands',
  BR: 'Brazil',
  BS: 'Bahamas',
  BT: 'Bhutan',
  BV: 'Bouvet Island',
  BW: 'Botswana',
  BY: 'Belarus',
  BZ: 'Belize',
  CA: 'Canada',
  CC: 'Cocos Islands',
  CD: 'Democratic Republic of the Congo',
  CF: 'Central African Republic',
  CG: 'Republic of the Congo',
  CH: 'Switzerland',
  CI: 'Ivory Coast',
  CK: 'Cook Islands',
  CL: 'Chile',
  CM: 'Cameroon',
  CN: 'China',
  CO: 'Colombia',
  CR: 'Costa Rica',
  CU: 'Cuba',
  CV: 'Cabo Verde',
  CW: 'Curaçao',
  CX: 'Christmas Island',
  CY: 'Cyprus',
  CZ: 'Czechia',
  DE: 'Germany',
  DJ: 'Djibouti',
  DK: 'Denmark',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  DZ: 'Algeria',
  EC: 'Ecuador',
  EE: 'Estonia',
  EG: 'Egypt',
  EH: 'Western Sahara',
  ER: 'Eritrea',
  ES: 'Spain',
  ET: 'Ethiopia',
  FI: 'Finland',
  FJ: 'Fiji',
  FK: 'Falkland Islands',
  FM: 'Micronesia',
  FO: 'Faroe Islands',
  FR: 'France',
  GA: 'Gabon',
  GB: 'United Kingdom',
  GD: 'Grenada',
  GE: 'Georgia',
  GF: 'French Guiana',
  GG: 'Guernsey',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GL: 'Greenland',
  GM: 'Gambia',
  GN: 'Guinea',
  GP: 'Guadeloupe',
  GQ: 'Equatorial Guinea',
  GR: 'Greece',
  GS: 'South Georgia and the South Sandwich Islands',
  GT: 'Guatemala',
  GU: 'Guam',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HK: 'Hong Kong',
  HM: 'Heard Island and McDonald Islands',
  HN: 'Honduras',
  HR: 'Croatia',
  HT: 'Haiti',
  HU: 'Hungary',
  ID: 'Indonesia',
  IE: 'Ireland',
  IL: 'Israel',
  IM: 'Isle of Man',
  IN: 'India',
  IO: 'British Indian Ocean Territory',
  IQ: 'Iraq',
  IR: 'Iran',
  IS: 'Iceland',
  IT: 'Italy',
  JE: 'Jersey',
  JM: 'Jamaica',
  JO: 'Jordan',
  JP: 'Japan',
  KE: 'Kenya',
  KG: 'Kyrgyzstan',
  KH: 'Cambodia',
  KI: 'Kiribati',
  KM: 'Comoros',
  KN: 'Saint Kitts and Nevis',
  KP: 'North Korea',
  KR: 'South Korea',
  KW: 'Kuwait',
  KY: 'Cayman Islands',
  KZ: 'Kazakhstan',
  LA: 'Laos',
  LB: 'Lebanon',
  LC: 'Saint Lucia',
  LI: 'Liechtenstein',
  LK: 'Sri Lanka',
  LR: 'Liberia',
  LS: 'Lesotho',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LV: 'Latvia',
  LY: 'Libya',
  MA: 'Morocco',
  MC: 'Monaco',
  MD: 'Moldova',
  ME: 'Montenegro',
  MF: 'Saint Martin',
  MG: 'Madagascar',
  MH: 'Marshall Islands',
  MK: 'North Macedonia',
  ML: 'Mali',
  MM: 'Myanmar',
  MN: 'Mongolia',
  MO: 'Macao',
  MP: 'Northern Mariana Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MS: 'Montserrat',
  MT: 'Malta',
  MU: 'Mauritius',
  MV: 'Maldives',
  MW: 'Malawi',
  MX: 'Mexico',
  MY: 'Malaysia',
  MZ: 'Mozambique',
  NA: 'Namibia',
  NC: 'New Caledonia',
  NE: 'Niger',
  NF: 'Norfolk Island',
  NG: 'Nigeria',
  NI: 'Nicaragua',
  NL: 'Netherlands',
  NO: 'Norway',
  NP: 'Nepal',
  NR: 'Nauru',
  NU: 'Niue',
  NZ: 'New Zealand',
  OM: 'Oman',
  PA: 'Panama',
  PE: 'Peru',
  PF: 'French Polynesia',
  PG: 'Papua New Guinea',
  PH: 'Philippines',
  PK: 'Pakistan',
  PL: 'Poland',
  PM: 'Saint Pierre and Miquelon',
  PN: 'Pitcairn',
  PR: 'Puerto Rico',
  PS: 'Palestine',
  PT: 'Portugal',
  PW: 'Palau',
  PY: 'Paraguay',
  QA: 'Qatar',
  RE: 'Réunion',
  RO: 'Romania',
  RS: 'Serbia',
  RU: 'Russia',
  RW: 'Rwanda',
  SA: 'Saudi Arabia',
  SB: 'Solomon Islands',
  SC: 'Seychelles',
  SD: 'Sudan',
  SE: 'Sweden',
  SG: 'Singapore',
  SH: 'Saint Helena, Ascension and Tristan da Cunha',
  SI: 'Slovenia',
  SJ: 'Svalbard and Jan Mayen',
  SK: 'Slovakia',
  SL: 'Sierra Leone',
  SM: 'San Marino',
  SN: 'Senegal',
  SO: 'Somalia',
  SR: 'Suriname',
  SS: 'South Sudan',
  ST: 'Sao Tome and Principe',
  SV: 'El Salvador',
  SX: 'Sint Maarten',
  SY: 'Syria',
  SZ: 'Eswatini',
  TC: 'Turks and Caicos Islands',
  TD: 'Chad',
  TF: 'French Southern Territories',
  TG: 'Togo',
  TH: 'Thailand',
  TJ: 'Tajikistan',
  TK: 'Tokelau',
  TL: 'Timor-Leste',
  TM: 'Turkmenistan',
  TN: 'Tunisia',
  TO: 'Tonga',
  TR: 'Turkey',
  TT: 'Trinidad and Tobago',
  TV: 'Tuvalu',
  TW: 'Taiwan',
  TZ: 'Tanzania',
  UA: 'Ukraine',
  UG: 'Uganda',
  UM: 'United States Minor Outlying Islands',
  US: 'United States of America',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VA: 'Holy See',
  VC: 'Saint Vincent and the Grenadines',
  VE: 'Venezuela',
  VG: 'Virgin Islands (UK)',
  VI: 'Virgin Islands (US)',
  VN: 'Vietnam',
  VU: 'Vanuatu',
  WF: 'Wallis and Futuna',
  WS: 'Samoa',
  YE: 'Yemen',
  YT: 'Mayotte',
  ZA: 'South Africa',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
}

const TIMEZONES = {
  'Asia/Kabul': {
    c: 'AF',
  },
  'Europe/Mariehamn': {
    c: 'AX',
  },
  'Europe/Tirane': {
    c: 'AL',
  },
  'Africa/Algiers': {
    c: 'DZ',
  },
  'Pacific/Pago_Pago': {
    c: 'AS',
  },
  'Europe/Andorra': {
    c: 'AD',
  },
  'Africa/Luanda': {
    c: 'AO',
  },
  'America/Anguilla': {
    c: 'AI',
  },
  'Antarctica/McMurdo': {
    c: 'AQ',
  },
  'Antarctica/South_Pole': {
    c: 'AQ',
  },
  'Antarctica/Rothera': {
    c: 'AQ',
  },
  'Antarctica/Palmer': {
    c: 'AQ',
  },
  'Antarctica/Mawson': {
    c: 'AQ',
  },
  'Antarctica/Davis': {
    c: 'AQ',
  },
  'Antarctica/Casey': {
    c: 'AQ',
  },
  'Antarctica/Vostok': {
    c: 'AQ',
  },
  'Antarctica/DumontDUrville': {
    c: 'AQ',
  },
  'Antarctica/Syowa': {
    c: 'AQ',
  },
  'America/Antigua': {
    c: 'AG',
  },
  'America/Argentina/Buenos_Aires': {
    c: 'AR',
  },
  'America/Argentina/Cordoba': {
    c: 'AR',
  },
  'America/Argentina/Salta': {
    c: 'AR',
  },
  'America/Argentina/Jujuy': {
    c: 'AR',
  },
  'America/Argentina/Tucuman': {
    c: 'AR',
  },
  'America/Argentina/Catamarca': {
    c: 'AR',
  },
  'America/Argentina/La_Rioja': {
    c: 'AR',
  },
  'America/Argentina/San_Juan': {
    c: 'AR',
  },
  'America/Argentina/Mendoza': {
    c: 'AR',
  },
  'America/Argentina/San_Luis': {
    c: 'AR',
  },
  'America/Argentina/Rio_Gallegos': {
    c: 'AR',
  },
  'America/Argentina/Ushuaia': {
    c: 'AR',
  },
  'Asia/Yerevan': {
    c: 'AM',
  },
  'America/Aruba': {
    c: 'AW',
  },
  'Australia/Lord_Howe': {
    c: 'AU',
  },
  'Antarctica/Macquarie': {
    c: 'AU',
  },
  'Australia/Hobart': {
    c: 'AU',
  },
  'Australia/Currie': {
    c: 'AU',
  },
  'Australia/Melbourne': {
    c: 'AU',
  },
  'Australia/Sydney': {
    c: 'AU',
  },
  'Australia/Broken_Hill': {
    c: 'AU',
  },
  'Australia/Brisbane': {
    c: 'AU',
  },
  'Australia/Lindeman': {
    c: 'AU',
  },
  'Australia/Adelaide': {
    c: 'AU',
  },
  'Australia/Darwin': {
    c: 'AU',
  },
  'Australia/Perth': {
    c: 'AU',
  },
  'Australia/Eucla': {
    c: 'AU',
  },
  'Europe/Vienna': {
    c: 'AT',
  },
  'Asia/Baku': {
    c: 'AZ',
  },
  'America/Nassau': {
    c: 'BS',
  },
  'Asia/Bahrain': {
    c: 'BH',
  },
  'Asia/Dhaka': {
    c: 'BD',
  },
  'America/Barbados': {
    c: 'BB',
  },
  'Europe/Minsk': {
    c: 'BY',
  },
  'Europe/Brussels': {
    c: 'BE',
  },
  'America/Belize': {
    c: 'BZ',
  },
  'Africa/Porto-Novo': {
    c: 'BJ',
  },
  'Atlantic/Bermuda': {
    c: 'BM',
  },
  'Asia/Thimphu': {
    c: 'BT',
  },
  'America/La_Paz': {
    c: 'BO',
  },
  'Europe/Sarajevo': {
    c: 'BA',
  },
  'Africa/Gaborone': {
    c: 'BW',
  },
  'America/Noronha': {
    c: 'BR',
  },
  'America/Belem': {
    c: 'BR',
  },
  'America/Fortaleza': {
    c: 'BR',
  },
  'America/Recife': {
    c: 'BR',
  },
  'America/Araguaina': {
    c: 'BR',
  },
  'America/Maceio': {
    c: 'BR',
  },
  'America/Bahia': {
    c: 'BR',
  },
  'America/Sao_Paulo': {
    c: 'BR',
  },
  'America/Campo_Grande': {
    c: 'BR',
  },
  'America/Cuiaba': {
    c: 'BR',
  },
  'America/Santarem': {
    c: 'BR',
  },
  'America/Porto_Velho': {
    c: 'BR',
  },
  'America/Boa_Vista': {
    c: 'BR',
  },
  'America/Manaus': {
    c: 'BR',
  },
  'America/Eirunepe': {
    c: 'BR',
  },
  'America/Rio_Branco': {
    c: 'BR',
  },
  'Indian/Chagos': {
    c: 'IO',
  },
  'Asia/Brunei': {
    c: 'BN',
  },
  'Europe/Sofia': {
    c: 'BG',
  },
  'Africa/Ouagadougou': {
    c: 'BF',
  },
  'Africa/Bujumbura': {
    c: 'BI',
  },
  'Asia/Phnom_Penh': {
    c: 'KH',
  },
  'Africa/Douala': {
    c: 'CM',
  },
  'America/St_Johns': {
    c: 'CA',
  },
  'America/Halifax': {
    c: 'CA',
  },
  'America/Glace_Bay': {
    c: 'CA',
  },
  'America/Moncton': {
    c: 'CA',
  },
  'America/Goose_Bay': {
    c: 'CA',
  },
  'America/Blanc-Sablon': {
    c: 'CA',
  },
  'America/Montreal': {
    c: 'CA',
  },
  'America/Toronto': {
    c: 'CA',
  },
  'America/Nipigon': {
    c: 'CA',
  },
  'America/Thunder_Bay': {
    c: 'CA',
  },
  'America/Iqaluit': {
    c: 'CA',
  },
  'America/Pangnirtung': {
    c: 'CA',
  },
  'America/Resolute': {
    c: 'CA',
  },
  'America/Atikokan': {
    c: 'CA',
  },
  'America/Rankin_Inlet': {
    c: 'CA',
  },
  'America/Winnipeg': {
    c: 'CA',
  },
  'America/Rainy_River': {
    c: 'CA',
  },
  'America/Regina': {
    c: 'CA',
  },
  'America/Swift_Current': {
    c: 'CA',
  },
  'America/Edmonton': {
    c: 'CA',
  },
  'America/Cambridge_Bay': {
    c: 'CA',
  },
  'America/Yellowknife': {
    c: 'CA',
  },
  'America/Inuvik': {
    c: 'CA',
  },
  'America/Creston': {
    c: 'CA',
  },
  'America/Dawson_Creek': {
    c: 'CA',
  },
  'America/Vancouver': {
    c: 'CA',
  },
  'America/Whitehorse': {
    c: 'CA',
  },
  'America/Dawson': {
    c: 'CA',
  },
  'Atlantic/Cape_Verde': {
    c: 'CV',
  },
  'America/Cayman': {
    c: 'KY',
  },
  'Africa/Bangui': {
    c: 'CF',
  },
  'Africa/Ndjamena': {
    c: 'TD',
  },
  'America/Santiago': {
    c: 'CL',
  },
  'Pacific/Easter': {
    c: 'CL',
  },
  'Asia/Shanghai': {
    c: 'CN',
  },
  'Asia/Harbin': {
    c: 'CN',
  },
  'Asia/Chongqing': {
    c: 'CN',
  },
  'Asia/Urumqi': {
    c: 'CN',
  },
  'Asia/Kashgar': {
    c: 'CN',
  },
  'Indian/Christmas': {
    c: 'CX',
  },
  'Indian/Cocos': {
    c: 'CC',
  },
  'America/Bogota': {
    c: 'CO',
  },
  'Indian/Comoro': {
    c: 'KM',
  },
  'Africa/Brazzaville': {
    c: 'CG',
  },
  'Africa/Kinshasa': {
    c: 'CD',
  },
  'Africa/Lubumbashi': {
    c: 'CD',
  },
  'Pacific/Rarotonga': {
    c: 'CK',
  },
  'America/Costa_Rica': {
    c: 'CR',
  },
  'Africa/Abidjan': {
    c: 'CI',
  },
  'Europe/Zagreb': {
    c: 'HR',
  },
  'America/Havana': {
    c: 'CU',
  },
  'Asia/Nicosia': {
    c: 'CY',
  },
  'Europe/Prague': {
    c: 'CZ',
  },
  'Europe/Copenhagen': {
    c: 'DK',
  },
  'Africa/Djibouti': {
    c: 'DJ',
  },
  'America/Dominica': {
    c: 'DM',
  },
  'America/Santo_Domingo': {
    c: 'DO',
  },
  'America/Guayaquil': {
    c: 'EC',
  },
  'Pacific/Galapagos': {
    c: 'EC',
  },
  'Africa/Cairo': {
    c: 'EG',
  },
  'America/El_Salvador': {
    c: 'SV',
  },
  'Africa/Malabo': {
    c: 'GQ',
  },
  'Africa/Asmara': {
    c: 'ER',
  },
  'Europe/Tallinn': {
    c: 'EE',
  },
  'Africa/Addis_Ababa': {
    c: 'ET',
  },
  'Atlantic/Stanley': {
    c: 'FK',
  },
  'Atlantic/Faroe': {
    c: 'FO',
  },
  'Pacific/Fiji': {
    c: 'FJ',
  },
  'Europe/Helsinki': {
    c: 'FI',
  },
  'Europe/Paris': {
    c: 'FR',
  },
  'America/Cayenne': {
    c: 'GF',
  },
  'Pacific/Tahiti': {
    c: 'PF',
  },
  'Pacific/Marquesas': {
    c: 'PF',
  },
  'Pacific/Gambier': {
    c: 'PF',
  },
  'Indian/Kerguelen': {
    c: 'TF',
  },
  'Africa/Libreville': {
    c: 'GA',
  },
  'Africa/Banjul': {
    c: 'GM',
  },
  'Asia/Tbilisi': {
    c: 'GE',
  },
  'Europe/Berlin': {
    c: 'DE',
  },
  'Europe/Busingen': {
    c: 'DE',
  },
  'Africa/Accra': {
    c: 'GH',
  },
  'Europe/Gibraltar': {
    c: 'GI',
  },
  'Europe/Athens': {
    c: 'GR',
  },
  'America/Godthab': {
    c: 'GL',
  },
  'America/Danmarkshavn': {
    c: 'GL',
  },
  'America/Scoresbysund': {
    c: 'GL',
  },
  'America/Thule': {
    c: 'GL',
  },
  'America/Grenada': {
    c: 'GD',
  },
  'America/Guadeloupe': {
    c: 'GP',
  },
  'Pacific/Guam': {
    c: 'GU',
  },
  'America/Guatemala': {
    c: 'GT',
  },
  'Europe/Guernsey': {
    c: 'GG',
  },
  'Africa/Conakry': {
    c: 'GN',
  },
  'Africa/Bissau': {
    c: 'GW',
  },
  'America/Guyana': {
    c: 'GY',
  },
  'America/Port-au-Prince': {
    c: 'HT',
  },
  'Europe/Vatican': {
    c: 'VA',
  },
  'America/Tegucigalpa': {
    c: 'HN',
  },
  'Asia/Hong_Kong': {
    c: 'HK',
  },
  'Europe/Budapest': {
    c: 'HU',
  },
  'Atlantic/Reykjavik': {
    c: 'IS',
  },
  'Asia/Kolkata': {
    c: 'IN',
  },
  'Asia/Jakarta': {
    c: 'ID',
  },
  'Asia/Pontianak': {
    c: 'ID',
  },
  'Asia/Makassar': {
    c: 'ID',
  },
  'Asia/Jayapura': {
    c: 'ID',
  },
  'Asia/Tehran': {
    c: 'IR',
  },
  'Asia/Baghdad': {
    c: 'IQ',
  },
  'Europe/Dublin': {
    c: 'IE',
  },
  'Europe/Isle_of_Man': {
    c: 'IM',
  },
  'Asia/Jerusalem': {
    c: 'IL',
  },
  'Europe/Rome': {
    c: 'IT',
  },
  'America/Jamaica': {
    c: 'JM',
  },
  'Asia/Tokyo': {
    c: 'JP',
  },
  'Europe/Jersey': {
    c: 'JE',
  },
  'Asia/Amman': {
    c: 'JO',
  },
  'Asia/Almaty': {
    c: 'KZ',
  },
  'Asia/Qyzylorda': {
    c: 'KZ',
  },
  'Asia/Aqtobe': {
    c: 'KZ',
  },
  'Asia/Aqtau': {
    c: 'KZ',
  },
  'Asia/Oral': {
    c: 'KZ',
  },
  'Africa/Nairobi': {
    c: 'KE',
  },
  'Pacific/Tarawa': {
    c: 'KI',
  },
  'Pacific/Enderbury': {
    c: 'KI',
  },
  'Pacific/Kiritimati': {
    c: 'KI',
  },
  'Asia/Pyongyang': {
    c: 'KP',
  },
  'Asia/Seoul': {
    c: 'KR',
  },
  'Asia/Kuwait': {
    c: 'KW',
  },
  'Asia/Bishkek': {
    c: 'KG',
  },
  'Asia/Vientiane': {
    c: 'LA',
  },
  'Europe/Riga': {
    c: 'LV',
  },
  'Asia/Beirut': {
    c: 'LB',
  },
  'Africa/Maseru': {
    c: 'LS',
  },
  'Africa/Monrovia': {
    c: 'LR',
  },
  'Africa/Tripoli': {
    c: 'LY',
  },
  'Europe/Vaduz': {
    c: 'LI',
  },
  'Europe/Vilnius': {
    c: 'LT',
  },
  'Europe/Luxembourg': {
    c: 'LU',
  },
  'Asia/Macau': {
    c: 'MO',
  },
  'Europe/Skopje': {
    c: 'MK',
  },
  'Indian/Antananarivo': {
    c: 'MG',
  },
  'Africa/Blantyre': {
    c: 'MW',
  },
  'Asia/Kuala_Lumpur': {
    c: 'MY',
  },
  'Asia/Kuching': {
    c: 'MY',
  },
  'Indian/Maldives': {
    c: 'MV',
  },
  'Africa/Bamako': {
    c: 'ML',
  },
  'Europe/Malta': {
    c: 'MT',
  },
  'Pacific/Majuro': {
    c: 'MH',
  },
  'Pacific/Kwajalein': {
    c: 'MH',
  },
  'America/Martinique': {
    c: 'MQ',
  },
  'Africa/Nouakchott': {
    c: 'MR',
  },
  'Indian/Mauritius': {
    c: 'MU',
  },
  'Indian/Mayotte': {
    c: 'YT',
  },
  'America/Mexico_City': {
    c: 'MX',
  },
  'America/Cancun': {
    c: 'MX',
  },
  'America/Merida': {
    c: 'MX',
  },
  'America/Monterrey': {
    c: 'MX',
  },
  'America/Matamoros': {
    c: 'MX',
  },
  'America/Mazatlan': {
    c: 'MX',
  },
  'America/Chihuahua': {
    c: 'MX',
  },
  'America/Ojinaga': {
    c: 'MX',
  },
  'America/Hermosillo': {
    c: 'MX',
  },
  'America/Tijuana': {
    c: 'MX',
  },
  'America/Santa_Isabel': {
    c: 'MX',
  },
  'America/Bahia_Banderas': {
    c: 'MX',
  },
  'Pacific/Chuuk': {
    c: 'FM',
  },
  'Pacific/Pohnpei': {
    c: 'FM',
  },
  'Pacific/Kosrae': {
    c: 'FM',
  },
  'Europe/Chisinau': {
    c: 'MD',
  },
  'Europe/Monaco': {
    c: 'MC',
  },
  'Asia/Ulaanbaatar': {
    c: 'MN',
  },
  'Asia/Hovd': {
    c: 'MN',
  },
  'Asia/Choibalsan': {
    c: 'MN',
  },
  'Europe/Podgorica': {
    c: 'ME',
  },
  'America/Montserrat': {
    c: 'MS',
  },
  'Africa/Casablanca': {
    c: 'MA',
  },
  'Africa/Maputo': {
    c: 'MZ',
  },
  'Asia/Rangoon': {
    c: 'MM',
  },
  'Africa/Windhoek': {
    c: 'NA',
  },
  'Pacific/Nauru': {
    c: 'NR',
  },
  'Asia/Kathmandu': {
    c: 'NP',
  },
  'Europe/Amsterdam': {
    c: 'NL',
  },
  'Pacific/Noumea': {
    c: 'NC',
  },
  'Pacific/Auckland': {
    c: 'NZ',
  },
  'Pacific/Chatham': {
    c: 'NZ',
  },
  'America/Managua': {
    c: 'NI',
  },
  'Africa/Niamey': {
    c: 'NE',
  },
  'Africa/Lagos': {
    c: 'NG',
  },
  'Pacific/Niue': {
    c: 'NU',
  },
  'Pacific/Norfolk': {
    c: 'NF',
  },
  'Pacific/Saipan': {
    c: 'MP',
  },
  'Europe/Oslo': {
    c: 'NO',
  },
  'Asia/Muscat': {
    c: 'OM',
  },
  'Asia/Karachi': {
    c: 'PK',
  },
  'Pacific/Palau': {
    c: 'PW',
  },
  'Asia/Gaza': {
    c: 'PS',
  },
  'Asia/Hebron': {
    c: 'PS',
  },
  'America/Panama': {
    c: 'PA',
  },
  'Pacific/Port_Moresby': {
    c: 'PG',
  },
  'America/Asuncion': {
    c: 'PY',
  },
  'America/Lima': {
    c: 'PE',
  },
  'Asia/Manila': {
    c: 'PH',
  },
  'Pacific/Pitcairn': {
    c: 'PN',
  },
  'Europe/Warsaw': {
    c: 'PL',
  },
  'Europe/Lisbon': {
    c: 'PT',
  },
  'Atlantic/Madeira': {
    c: 'PT',
  },
  'Atlantic/Azores': {
    c: 'PT',
  },
  'America/Puerto_Rico': {
    c: 'PR',
  },
  'Asia/Qatar': {
    c: 'QA',
  },
  'Indian/Reunion': {
    c: 'RE',
  },
  'Europe/Bucharest': {
    c: 'RO',
  },
  'Europe/Kaliningrad': {
    c: 'RU',
  },
  'Europe/Moscow': {
    c: 'RU',
  },
  'Europe/Volgograd': {
    c: 'RU',
  },
  'Europe/Samara': {
    c: 'RU',
  },
  'Asia/Yekaterinburg': {
    c: 'RU',
  },
  'Asia/Omsk': {
    c: 'RU',
  },
  'Asia/Novosibirsk': {
    c: 'RU',
  },
  'Asia/Novokuznetsk': {
    c: 'RU',
  },
  'Asia/Krasnoyarsk': {
    c: 'RU',
  },
  'Asia/Irkutsk': {
    c: 'RU',
  },
  'Asia/Yakutsk': {
    c: 'RU',
  },
  'Asia/Khandyga': {
    c: 'RU',
  },
  'Asia/Vladivostok': {
    c: 'RU',
  },
  'Asia/Sakhalin': {
    c: 'RU',
  },
  'Asia/Ust-Nera': {
    c: 'RU',
  },
  'Asia/Magadan': {
    c: 'RU',
  },
  'Asia/Kamchatka': {
    c: 'RU',
  },
  'Asia/Anadyr': {
    c: 'RU',
  },
  'Africa/Kigali': {
    c: 'RW',
  },
  'America/St_Barthelemy': {
    c: 'BL',
  },
  'Atlantic/St_Helena': {
    c: 'SH',
  },
  'America/St_Kitts': {
    c: 'KN',
  },
  'America/St_Lucia': {
    c: 'LC',
  },
  'America/Marigot': {
    c: 'MF',
  },
  'America/Miquelon': {
    c: 'PM',
  },
  'America/St_Vincent': {
    c: 'VC',
  },
  'Pacific/Apia': {
    c: 'WS',
  },
  'Europe/San_Marino': {
    c: 'SM',
  },
  'Africa/Sao_Tome': {
    c: 'ST',
  },
  'Asia/Riyadh': {
    c: 'SA',
  },
  'Africa/Dakar': {
    c: 'SN',
  },
  'Europe/Belgrade': {
    c: 'RS',
  },
  'Indian/Mahe': {
    c: 'SC',
  },
  'Africa/Freetown': {
    c: 'SL',
  },
  'Asia/Singapore': {
    c: 'SG',
  },
  'Europe/Bratislava': {
    c: 'SK',
  },
  'Europe/Ljubljana': {
    c: 'SI',
  },
  'Pacific/Guadalcanal': {
    c: 'SB',
  },
  'Africa/Mogadishu': {
    c: 'SO',
  },
  'Africa/Johannesburg': {
    c: 'ZA',
  },
  'Atlantic/South_Georgia': {
    c: 'GS',
  },
  'Europe/Madrid': {
    c: 'ES',
  },
  'Africa/Ceuta': {
    c: 'ES',
  },
  'Atlantic/Canary': {
    c: 'ES',
  },
  'Asia/Colombo': {
    c: 'LK',
  },
  'Africa/Khartoum': {
    c: 'SD',
  },
  'America/Paramaribo': {
    c: 'SR',
  },
  'Arctic/Longyearbyen': {
    c: 'SJ',
  },
  'Africa/Mbabane': {
    c: 'SZ',
  },
  'Europe/Stockholm': {
    c: 'SE',
  },
  'Europe/Zurich': {
    c: 'CH',
  },
  'Asia/Damascus': {
    c: 'SY',
  },
  'Asia/Taipei': {
    c: 'TW',
  },
  'Asia/Dushanbe': {
    c: 'TJ',
  },
  'Africa/Dar_es_Salaam': {
    c: 'TZ',
  },
  'Asia/Bangkok': {
    c: 'TH',
  },
  'Asia/Dili': {
    c: 'TL',
  },
  'Africa/Lome': {
    c: 'TG',
  },
  'Pacific/Fakaofo': {
    c: 'TK',
  },
  'Pacific/Tongatapu': {
    c: 'TO',
  },
  'America/Port_of_Spain': {
    c: 'TT',
  },
  'Africa/Tunis': {
    c: 'TN',
  },
  'Europe/Istanbul': {
    c: 'TR',
  },
  'Asia/Ashgabat': {
    c: 'TM',
  },
  'America/Grand_Turk': {
    c: 'TC',
  },
  'Pacific/Funafuti': {
    c: 'TV',
  },
  'Africa/Kampala': {
    c: 'UG',
  },
  'Europe/Kiev': {
    c: 'UA',
  },
  'Europe/Uzhgorod': {
    c: 'UA',
  },
  'Europe/Zaporozhye': {
    c: 'UA',
  },
  'Europe/Simferopol': {
    c: 'UA',
  },
  'Asia/Dubai': {
    c: 'AE',
  },
  'Europe/London': {
    c: 'GB',
  },
  'America/New_York': {
    c: 'US',
  },
  'America/Detroit': {
    c: 'US',
  },
  'America/Kentucky/Louisville': {
    c: 'US',
  },
  'America/Kentucky/Monticello': {
    c: 'US',
  },
  'America/Indiana/Indianapolis': {
    c: 'US',
  },
  'America/Indiana/Vincennes': {
    c: 'US',
  },
  'America/Indiana/Winamac': {
    c: 'US',
  },
  'America/Indiana/Marengo': {
    c: 'US',
  },
  'America/Indiana/Petersburg': {
    c: 'US',
  },
  'America/Indiana/Vevay': {
    c: 'US',
  },
  'America/Chicago': {
    c: 'US',
  },
  'America/Indiana/Tell_City': {
    c: 'US',
  },
  'America/Indiana/Knox': {
    c: 'US',
  },
  'America/Menominee': {
    c: 'US',
  },
  'America/North_Dakota/Center': {
    c: 'US',
  },
  'America/North_Dakota/New_Salem': {
    c: 'US',
  },
  'America/North_Dakota/Beulah': {
    c: 'US',
  },
  'America/Denver': {
    c: 'US',
  },
  'America/Boise': {
    c: 'US',
  },
  'America/Shiprock': {
    c: 'US',
  },
  'America/Phoenix': {
    c: 'US',
  },
  'America/Los_Angeles': {
    c: 'US',
  },
  'America/Anchorage': {
    c: 'US',
  },
  'America/Juneau': {
    c: 'US',
  },
  'America/Sitka': {
    c: 'US',
  },
  'America/Yakutat': {
    c: 'US',
  },
  'America/Nome': {
    c: 'US',
  },
  'America/Adak': {
    c: 'US',
  },
  'America/Metlakatla': {
    c: 'US',
  },
  'Pacific/Honolulu': {
    c: 'US',
  },
  'Pacific/Johnston': {
    c: 'UM',
  },
  'Pacific/Midway': {
    c: 'UM',
  },
  'Pacific/Wake': {
    c: 'UM',
  },
  'America/Montevideo': {
    c: 'UY',
  },
  'Asia/Samarkand': {
    c: 'UZ',
  },
  'Asia/Tashkent': {
    c: 'UZ',
  },
  'Pacific/Efate': {
    c: 'VU',
  },
  'America/Caracas': {
    c: 'VE',
  },
  'Asia/Ho_Chi_Minh': {
    c: 'VN',
  },
  'America/Tortola': {
    c: 'VG',
  },
  'America/St_Thomas': {
    c: 'VI',
  },
  'Pacific/Wallis': {
    c: 'WF',
  },
  'Africa/El_Aaiun': {
    c: 'EH',
  },
  'Asia/Aden': {
    c: 'YE',
  },
  'Africa/Lusaka': {
    c: 'ZM',
  },
  'Africa/Harare': {
    c: 'ZW',
  },
}

export default function useCountry() {
  const contries = ref(COUNTRIES)
  const timeZones = ref(TIMEZONES)

  const getCountryCode = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    if (timezone === '' || !timezone) {
      return null
    }

    let countryCode = 'US'
    const timezoneCountry = timeZones.value[timezone]
    if (timezoneCountry && timezoneCountry.c) countryCode = timezoneCountry.c

    return countryCode
  }

  const getCountry = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    if (timezone === '' || !timezone) {
      return null
    }

    let countryCode = 'US'
    const timezoneCountry = timeZones.value[timezone]
    if (timezoneCountry && timezoneCountry.c) countryCode = timezoneCountry.c
    const country = contries.value[countryCode]

    return country
  }

  const getState = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    if (timezone === '' || !timezone) {
      return null
    }

    const state = timezone.split('/')[1].replace('_', ' ')

    return state
  }

  return {
    getState,
    getCountryCode,
    getCountry,
  }
}
